<template>
  <main>
    <div class="container page-content">
      <div v-if="post">
        <div class="float-end">
          <router-link
            v-if="post.showOnNewsPage"
            class="offering-link"
            :to="{ name: 'KnowledgeBase' }"
            ><h4>&times;</h4></router-link
          >
          <router-link v-else class="offering-link" :to="{ name: 'About' }"
            ><h4>&times;</h4></router-link
          >
        </div>
        <div class="row pb-3">
          <div class="col">
            <h1 id="blog-title">{{ post.headline }}</h1>
          </div>
        </div>
        <div class="row">
          <div id="media" class="col-12">
            <img
              class="img-fluid"
              v-if="post.imageURL"
              focusable="false"
              :src="post.imageURL"
              alt="Post Image"
            />
          </div>
        </div>

        <!-- MAIN CONTENT -->
        <div class="row mt-3 mb-5">
          <p v-html="post.story"></p>
        </div>
      </div>
      <div v-else>{{ errorPost }}</div>
    </div>
  </main>
  <Footer v-if="post || errorPost" />
</template>

<script>
import getDocument from "@/composables/getDocument";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id"],
  components: {
    Footer
  },
  setup(props) {
    const { error: errorPost, document: post } = getDocument(
      "rhNews",
      props.id
    );

    return {
      post,
      errorPost
    };
  }
};
</script>

<style>
</style>